import { useTranslation } from "react-i18next";

import { Avatar } from "components/Avatar";
import { Card } from "components/Card/Card";
import { CustomerSegmentDropdown } from "components/PageCustomer/CustomerSegmentDropdown";
import { Pill } from "components/Pill";

import { formatCurrency } from "lib/formatNumber";

import styles from "./CustomerAvatarCard.module.scss";

type CustomerAvatarCardProps = {
  userId: string;
  userName: string;
  trb: number;
  clientImgSrc?: string;
  userProfileImage?: string;
  children: React.ReactNode;
};

export function CustomerAvatarCard({
  userId,
  userName,
  trb,
  clientImgSrc,
  userProfileImage,
  children,
}: CustomerAvatarCardProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <div className={styles.customerAvatarCardWrapper}>
        <Avatar
          userName={userName}
          toShowUsername={true}
          profileImage={userProfileImage}
        />

        {/* TODO: Add Super Admin Check */}
        {clientImgSrc && (
          <div className={styles.customerAvatarCardClientOf}>
            <p>{t("pages.customer.clientOf")}</p>
            <img src={clientImgSrc} />
          </div>
        )}

        {/* Action buttons */}
        <div className={styles.customerAvatarCardActionButtons}>
          <div>
            <p>{t("pages.customersCases.tableColumns.customerId")}</p>
            <Pill text={userId} />
          </div>
          <div>
            <p>{t("pages.customersCases.tableColumns.profile")}</p>
            <CustomerSegmentDropdown />
          </div>
          <div>
            <p>{t("pages.customersCases.tableColumns.balance")}</p>
            <Pill text={formatCurrency(trb)} />
          </div>
        </div>

        {/* Children */}
        {children}
      </div>
    </Card>
  );
}
