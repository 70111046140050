import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";

import { DashboardHeader } from "components/DashboardHeader";
import { Modal } from "components/Modal";
import { Table } from "components/Table";
import { Tabs } from "components/Tabs";
import { WizardNewClaim } from "components/WizardNewClaim";

import { useHead } from "lib/hooks/useHead";
import {
  useGetCasesQuery,
  useGetCustomersQuery,
} from "lib/slices/clientServiceAPISlice";

import styles from "./PageCustomersCases.module.scss";
import { caseColumns } from "./TableCases";
import { customerCOColumns, customerColumns } from "./TableCustomers";

type Props = {
  defaultView: "customer" | "case";
};

export function PageCustomersCases({ defaultView }: Props) {
  const { t } = useTranslation();
  const { data: customers } = useGetCustomersQuery();
  const { data: cases } = useGetCasesQuery();
  const [claimModalOpen, setClaimModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useHead(t("pages.customersCases.header"));
  const { user } = useAuth();
  const role = user?.profile.roles;

  const onTabChange = (index: number) => {
    navigate(
      index === 0
        ? `/customers${location.state?.query ? location.state.query : ""}`
        : `/cases${location.state?.query ? location.state.query : ""}`,
      {
        state: { query: window.location.search },
      },
    );
  };

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader
          title={t("pages.customersCases.header")}
          subHeader={t("pages.customersCases.subHeader")}
        />

        <Tabs
          onChange={onTabChange}
          defaultIndex={defaultView === "customer" ? 0 : 1}
          tabs={[
            {
              title: t("pages.customersCases.customers"),
              content: customers && (
                <Table
                  data={customers}
                  columns={
                    role?.includes("client-operator")
                      ? customerCOColumns
                      : customerColumns
                  }
                  filters={true}
                  header={true}
                />
              ),
            },
            {
              title: t("pages.customersCases.cases"),
              content: cases && (
                <Table
                  data={cases}
                  columns={caseColumns}
                  filters={true}
                  header={true}
                />
              ),
            },
          ]}
        />
      </main>

      <Modal isOpen={claimModalOpen} onClose={() => setClaimModalOpen(false)}>
        <WizardNewClaim onCloseModal={() => setClaimModalOpen(false)} />
      </Modal>
    </>
  );
}
