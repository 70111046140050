import { classNames } from "lib/classNames";

import styles from "./Pill.module.scss";

type Props = {
  text: string | React.ReactNode;
  intent?: "Dark" | "Light" | "Active" | "Purple";
  color?: string;
};

export function Pill({ text, intent = "Light", color }: Props) {
  return (
    <p
      className={classNames(styles.pillContainer, styles[`pill${intent}`])}
      style={{ color }}
    >
      {text}
    </p>
  );
}
