import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import styles from "./CaseManagement.module.scss";

type CaseManagementProps = {
  onChangeView: (
    view: "information" | { cancel: boolean; pause: boolean },
  ) => void;
};

export function CaseManagement({ onChangeView }: CaseManagementProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.caseManagement}>
      <h3>{t("pages.casePage.caseManagement")}</h3>

      {/* TODO replace with real icons */}
      <div>
        <RInioButton
          onClick={onChangeView.bind(null, {
            cancel: true,
            pause: false,
          })}
          text="Cancel Case"
          icon="close"
        />
        <RInioButton
          onClick={onChangeView.bind(null, {
            cancel: false,
            pause: true,
          })}
          text="Pause Case"
          icon="lock"
        />
      </div>
    </div>
  );
}
