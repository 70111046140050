import { Skeleton } from "components/Skeleton";

import styles from "./MetricCard.module.scss";

export function MetricCardSkeleton() {
  return (
    <div className={styles.metricCardSkeleton}>
      <Skeleton width="50%" />
      <Skeleton width="100%" height={100} />
      <Skeleton width="100%" />
    </div>
  );
}
