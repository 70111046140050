import { useTranslation } from "react-i18next";

import { Avatar } from "components/Avatar";
import { Badge } from "components/Badge";

import styles from "./CaseInvoiceLogItem.module.scss";

type CaseInvoiceLogItemProps = {
  title: string;
  subject: string;
  from: string;
  date: Date;
  type: string;
  content?: string;
};

export function CaseInvoiceLogItem({
  title,
  subject,
  from,
  date,
  type,
  content,
}: CaseInvoiceLogItemProps) {
  const { t } = useTranslation();

  return (
    <div className={styles.caseInvoiceLogItemWrapper}>
      <Avatar size="medium" userName="Tele2" toShowUsername={false} />
      <div className={styles.caseInvoiceLogItemContent}>
        <div>
          <h3>{title}</h3>
          <p>
            <span>{t("pages.casePage.subject")}</span> {subject}
          </p>
          <p>
            <span>{t("pages.casePage.from")}</span> {from}
          </p>
          <div className={styles.caseInvoiceLogItemDate}>{`${date}`}</div>
        </div>
        <Badge status={type} />
      </div>
    </div>
  );
}
