import { flexRender, Table as ReactTable } from "@tanstack/react-table";
import { ReactNode } from "react";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";
import { usePagination } from "lib/hooks/usePagination";

import styles from "./Table.module.scss";
import { TablePagination } from "./TablePagination";

type TableBodyProps<TData> = {
  table: ReactTable<TData>;
  MobileElement?: ({ rowData }: { rowData: any[] }) => JSX.Element;
  children?: ReactNode;
  selectedRows?: string[];
  borderedTable?: boolean;
};

export function TableBody<TData>({
  table,
  MobileElement,
  children,
  selectedRows = [],
  borderedTable = false,
}: TableBodyProps<TData>) {
  const pagesToRender = usePagination(table);

  return (
    <>
      {MobileElement && (
        <div className={styles.tableContainerMobile}>
          {table.getRowModel().rows.map((row) => (
            <MobileElement key={row.id} rowData={row.getVisibleCells()} />
          ))}
        </div>
      )}
      <div className={styles.scrollableTable}>
        <table className={borderedTable ? styles.bordered : ""}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) =>
                  header.column.columnDef.header &&
                  !header.column.columnDef.meta?.hidden ? (
                    <th
                      key={header.id}
                      style={{
                        width: `${header.getSize()}px`,
                        cursor: header.column.getCanSort()
                          ? "pointer"
                          : undefined,
                      }}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className={styles.headerColumn}>
                        {/* @ts-expect-error headerIcon is a custom prop */}
                        {header.column.columnDef.meta?.headerIcon && (
                          <Icon
                            // @ts-expect-error headerIcon is a custom prop
                            name={header.column.columnDef.meta.headerIcon}
                            size={16}
                            color="#4A4A4A"
                          />
                        )}
                        {!header.isPlaceholder &&
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}

                        {header.column.getCanSort() && (
                          <>
                            {{
                              asc: (
                                <Icon
                                  name="chevronUp"
                                  size={12}
                                  color="#4a4a4a"
                                />
                              ),
                              desc: (
                                <Icon
                                  name="chevronDown"
                                  size={12}
                                  color="#4a4a4a"
                                />
                              ),
                            }[header.column.getIsSorted() as string] ?? (
                              <p>-</p>
                            )}
                          </>
                        )}
                      </div>
                    </th>
                  ) : null,
                )}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) =>
                  cell.column.columnDef.cell &&
                  !cell.column.columnDef.meta?.hidden ? (
                    <td
                      key={cell.id}
                      className={classNames({
                        [styles.selectedRow]: selectedRows.includes(row.id),
                      })}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ) : null,
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TablePagination table={table} pagesToRender={pagesToRender}>
        {children}
      </TablePagination>
    </>
  );
}
