import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { getStageStatus } from "lib/helpers";

import styles from "./PageCustomersCases.module.scss";

const columnHelper = createColumnHelper<Case>();

export const caseColumns = [
  columnHelper.accessor("customerName", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
    },
  }),
  columnHelper.accessor("customerId", {
    header: "Customer ID",
    cell: (info) => (
      <Link
        to={`/customer/${info.row.original.customerId}`}
        className={styles.customerIdColumn}
      >
        {info.row.original.customerId}
      </Link>
    ),
    meta: {
      headerIcon: "userprofilefocus",
    },
  }),
  columnHelper.accessor("id", {
    header: "Case ID",
    cell: (info) => (
      <Link
        to={`/customer/case/${info.row.original.id}`}
        className={styles.customerIdColumn}
      >
        {info.row.original.id}
      </Link>
    ),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
    },
  }),
  columnHelper.accessor("receiverCountryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
    },
  }),
  columnHelper.accessor("dueDate", {
    header: "Due Date",
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
    meta: {
      headerIcon: "blankcalendar",
    },
  }),
  columnHelper.accessor("totalAmount", {
    header: "TRB",
    cell: (info) => `${info.getValue()} EUR`,
    meta: {
      headerIcon: "bagdollar",
    },
  }),
  columnHelper.accessor("status", {
    id: "stage",
    header: "Stage",
    cell: (info) => <p>{getStageStatus(info.row.original.status).caseStage}</p>,
    meta: {
      headerIcon: "stageClockHand",
    },
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => (
      <p>{getStageStatus(info.row.original.status).caseStatus}</p>
    ),
    meta: {
      headerIcon: "streamLineFileCheck",
    },
  }),
];
