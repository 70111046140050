import { useTranslation } from "react-i18next";

import { progressColors } from "components/GraphStackedBar/GraphStackedBar";
import { Icon } from "components/Icon";
import { Tooltip } from "components/Tooltip";

import { classNames } from "lib/classNames";
import { formatNumber } from "lib/formatNumber";

import styles from "./MetricCard.module.scss";
import { Percent } from "./Percent";

type Props = {
  title: string;
  volume: {
    MoM: number;
    YoY: number;
    number: number;
  };
  files: {
    MoM: number;
    YoY: number;
    number: number;
  };
  theme: string;
};

export function MetricCard({ title, volume, files, theme = "white" }: Props) {
  const { t } = useTranslation();
  const iconColor = theme === "blue" ? "white" : "neutral";

  return (
    <div
      className={classNames(styles.metricCard, {
        [styles.metricCardBlue]: theme === "blue",
      })}
    >
      <div className={styles.metricCardHeader}>
        <p style={{ color: progressColors[title] }}>{title}</p>
        <Tooltip
          title={t("metricCard.tooltip.title")}
          text={t("metricCard.tooltip.text")}
        >
          <Icon name="informationcircle" size={14} intent={iconColor} />
        </Tooltip>
      </div>
      <div className={styles.metricCardContent}>
        <div className={styles.metric}>
          <div>
            <Icon name="megaphone" intent={iconColor} />
            <p>{t("metricCard.volume")}</p>
          </div>
          <p className={styles.number}>
            {t("currencyEUR", { amount: formatNumber(volume.number) })}
          </p>
          <div className={styles.percentages}>
            <Percent title="MoM" number={volume.MoM} />
            <span
              style={{ alignSelf: "baseline" }}
              className={styles.border}
            ></span>
            <Percent title="YoY" number={volume.YoY} />
          </div>
        </div>
        <span style={{ height: "auto" }} className={styles.border}></span>
        <div className={styles.metric}>
          <div>
            <Icon name="newfile" intent={iconColor} />
            <p>{t("metricCard.files")}</p>
          </div>
          <p className={styles.number}>{formatNumber(files.number)}</p>
          <div className={styles.percentages}>
            <Percent title="MoM" number={files.MoM} />
            <span
              style={{ alignSelf: "baseline" }}
              className={styles.border}
            ></span>
            <Percent title="YoY" number={files.YoY} />
          </div>
        </div>
      </div>
    </div>
  );
}
