import { useTranslation } from "react-i18next";

import validator from "@rjsf/validator-ajv8";

import { Form } from "components/Form";

type Props = {
  name?: string;
  surname?: string;
  email?: string;
  role?: string;
};

export function FormCreateUser({ name, surname, email, role }: Props) {
  const { t } = useTranslation();

  const formData = {
    validator,
    uiSchema: {
      "ui:submitButtonOptions": {
        parentProps: {
          hidden: true,
        },
      },
      name: {
        "ui:widget": "text",
        "ui:placeholder": "John",
      },
      surname: {
        "ui:widget": "text",
        "ui:placeholder": "Smith",
      },
      email: {
        "ui:widget": "email",
        "ui:placeholder": "johndoe@example.com",
      },
      role: {
        "ui:widget": "select",
      },
      requiredFields: {
        "ui:field": "requiredFieldsCustom"
      }
    },
    fields: {
      requiredFieldsCustom: () => <p>{t("pages.members.requiredFields")}</p>
    },
    schema: {
      type: "object",
      required: ["name", "surname", "email", "role"],
      properties: {
        name: {
          type: "string",
          title: t("formInviteMember.name"),
          default: name,
          maxLength: 100,
        },
        surname: {
          type: "string",
          title: t("formInviteMember.surname"),
          default: surname,
          maxLength: 100,
        },
        email: {
          type: "string",
          title: t("formInviteMember.email"),
          default: email,
          maxLength: 50,
        },
        role: {
          type: "string",
          title: t("formInviteMember.role"),
          default: role,
          enum: [
            t("formInviteMember.clientManager"),
            t("formInviteMember.clientOperator"),
            t("formInviteMember.customerOperator"),
            t("formInviteMember.keyAccountManager"),
          ],
        },
        requiredFields: {
          type: "string"
        }
      },
    }
  };

  return <Form id="new-member-form" {...formData} />;
}
