import { FieldTemplateProps } from "@rjsf/utils";

import styles from "./FieldTemplate.module.scss";

/**
 * Base structure for form elements
 * https://rjsf-team.github.io/react-jsonschema-form/docs/advanced-customization/custom-templates/#fieldtemplate
 */
export function FieldTemplate(props: FieldTemplateProps) {
  const {
    id,
    classNames,
    style,
    label,
    displayLabel = true,
    help,
    description,
    errors,
    children,
    uiSchema,
  } = props;

  // Determine if label should use "htmlFor" or "id"
  const isWidgetWithIdLabel =
    typeof uiSchema?.["ui:widget"] === "string" &&
    ["select", "radio"].includes(uiSchema["ui:widget"]);

  return (
    <div className={classNames} style={style}>
      {displayLabel && (
        <label {...(isWidgetWithIdLabel ? { id } : { htmlFor: id })}>
          <p className={styles.label}>{label}</p>
        </label>
      )}
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
}
