import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import { Badge } from "components/Badge";
import { Button } from "components/Button";
import { DashboardHeader } from "components/DashboardHeader";
import { FormCreateUser } from "components/FormCreateUser";
import { Modal } from "components/Modal";
import { Table } from "components/Table";

import { useHead } from "lib/hooks/useHead";
import useMediaQuery from "lib/hooks/useMediaQuery";
import { useGetMembersQuery } from "lib/slices/mockJsonAPISlice";

import breakpoints from "styles/mixins.scss";

import styles from "./PageMembers.module.scss";

const columnHelper = createColumnHelper();

type EditModal = {
  name: string;
  surname: string;
  email: string;
  role: string;
};

export function PageMembers() {
  const { t } = useTranslation();
  const { data } = useGetMembersQuery();
  const [tableData, setTableData] = useState<Member[]>(data || []);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState<EditModal | boolean>(
    false,
  );
  const [selectedRows, setSelectedRows] = useState<string[]>([]);

  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop})`);

  useHead(t("pages.members.header"));

  useEffect(() => {
    data && setTableData(data);
  }, [data]);

  const addForDeletion = (rowId: string) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((r) => r !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const columns = [
    columnHelper.display({
      id: "actions",
      size: 50,
      cell: (row) => (
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            onChange={() => addForDeletion(row.row.id)}
            checked={selectedRows.includes(row.row.id)}
          />
        </div>
      ),
    }),

    columnHelper.accessor("firstname", {
      header: t("pages.members.tableColumns.name"),
      // @ts-expect-error filterType is custom
      filterType: "text",
    }),
    columnHelper.accessor("surname", {
      header: t("pages.members.tableColumns.surname"),
      // @ts-expect-error filterType is custom
      filterType: "text",
    }),
    columnHelper.accessor("email", {
      header: t("pages.members.tableColumns.email"),
      // @ts-expect-error filterType is custom
      filterType: "text",
    }),
    columnHelper.accessor("role", {
      header: t("pages.members.tableColumns.role"),
      // @ts-expect-error filterType is custom
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
      // @ts-expect-error type error
      cell: ({ row }) => <p style={{ fontWeight: 500 }}>{row.original.role}</p>,
    }),
    columnHelper.accessor("status", {
      header: t("pages.members.tableColumns.status"),
      // @ts-expect-error filterType is custom
      filterType: "checkboxes",
      filterFn: "arrIncludesSome",
      // @ts-expect-error type error
      cell: ({ row }) => <Badge status={row.original.status} />,
    }),
    columnHelper.display({
      id: "button",
      size: 50,
      cell: ({ row }) => (
        <Button
          icon={{ name: "pencil", intent: "black" }}
          intent="clear"
          onClick={() =>
            setEditModalOpen({
              // @ts-expect-error type error
              name: row.original.firstname,
              // @ts-expect-error type error
              surname: row.original.surname,
              // @ts-expect-error type error
              email: row.original.email,
              // @ts-expect-error type error
              role: row.original.role,
            })
          }
        />
      ),
    }),
  ];

  return (
    <>
      <main className={styles.main}>
        <DashboardHeader
          title={t("pages.members.header")}
          subHeader={t("pages.members.subHeader")}
          badge={`${data?.length} users`}
        />
        {tableData && (
          <Table
            data={tableData}
            setData={setTableData}
            columns={columns}
            header={true}
            selectedRows={selectedRows}
            deleteRows={true}
            // mobileElement={TableMembersMobile}
            headerCta={
              <RInioButton
                icon="plus"
                text={isDesktop ? t("pages.members.createUser") : undefined}
                onClick={() => setCreateModalOpen(true)}
              />
            }
          />
        )}
      </main>

      <Modal
        isOpen={editModalOpen !== false}
        onClose={() => setEditModalOpen(false)}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.members.editUser")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          <FormCreateUser
            // @ts-expect-error type error
            name={editModalOpen.name}
            // @ts-expect-error type error
            surname={editModalOpen.surname}
            // @ts-expect-error type error
            email={editModalOpen.email}
            // @ts-expect-error type error
            role={editModalOpen.role}
          />
          <div className={styles.btnContainer}>
            <RInioButton
              text={t("save")}
              type="submit"
              form="new-member-form"
              onClick={() => setEditModalOpen(false)}
            />
            <RInioButton
              text={t("cancel")}
              intent="tertiary"
              onClick={() => setEditModalOpen(false)}
            />
          </div>
        </div>
      </Modal>

      <Modal isOpen={createModalOpen} onClose={() => setCreateModalOpen(false)}>
        <div className={styles.modal}>
          <div className={styles.header}>
            <h2>{t("pages.members.createUser")}</h2>
            <p>{t("newClaim.subHeader")}</p>
          </div>
          <FormCreateUser />
          <div className={styles.btnContainer}>
            <RInioButton
              text={t("save")}
              type="submit"
              form="new-member-form"
              onClick={() => setCreateModalOpen(false)}
            />
            <RInioButton
              text={t("cancel")}
              intent="tertiary"
              onClick={() => setCreateModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
