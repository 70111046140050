import { Icon } from "components/Icon";

import styles from "./DropdownUser.module.scss";

type Props = {
  accountId: string;
  title: string;
};

export function DropdownAccount({ accountId, title }: Props) {
  // TODO: Implement function
  const changeUserAccount = () => undefined;

  return (
    <button className={styles.dropdownAccount} onClick={changeUserAccount}>
      <Icon name="usercirclesingle" />
      <div>
        <p>{accountId}</p>
        <p>{title}</p>
      </div>
    </button>
  );
}
