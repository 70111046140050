import { ReactNode } from "react";
import { useAuth } from "react-oidc-context";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

export function PrivateSARoute({ children }: Props) {
  const { user } = useAuth();

  const authorised = true;

  if (!authorised) {
    // Redirect user if their role is not super admin
    return <Navigate to="/" />;
  }

  return children;
}
