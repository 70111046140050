import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { WidgetProps } from "@rjsf/utils";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./SelectCase.module.scss";
import { CaseState, ChangeCaseState } from "./types";

export function SelectSingleCase(
  props: WidgetProps & {
    onChangeCaseState: ChangeCaseState;
    caseState: CaseState;
  },
) {
  const { value, onChange, onChangeCaseState, caseState } = props;
  const derivedValue =
    value === caseState.cancelSingleCase?.reason
      ? value
      : caseState.cancelSingleCase.reason;

  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleChangeDropdownVisibility = () => setOpenDropdown((prev) => !prev);

  const handleUpdateSelected = (val: string) => {
    onChangeCaseState("cancelSingleCase", {
      reason: val,
    });
    onChange(val);
  };

  const handleRemoveSelected = (e: Event) => {
    e.stopPropagation();
    onChangeCaseState("cancelSingleCase", {
      reason: "",
    });
    onChange(null);
  };

  const divRef = useRef<HTMLDivElement>(null);

  useOutsideClick(divRef, () => {
    setOpenDropdown(false);
  });

  return (
    <div className={styles.selectCaseReasonWrapper} ref={divRef}>
      <div className={styles.selectCaseReasonPreview}>
        <button
          type="button"
          className={
            openDropdown || derivedValue
              ? styles.selectedButtonPreview
              : styles.unselectedButtonPreview
          }
          onClick={handleChangeDropdownVisibility}
        >
          <p>
            {derivedValue ? derivedValue : t("pages.casePage.singleOption")}
          </p>
          <div>
            {derivedValue && (
              <Icon
                name="delete1"
                intent="black"
                size={12}
                onClick={handleRemoveSelected}
              />
            )}
            <div
              className={classNames(styles.iconContainer, {
                [styles.iconContainerOpen]: openDropdown,
              })}
            >
              <Icon name="chevronRight" intent="black" size={12} />
            </div>
          </div>
        </button>
      </div>

      <div
        className={classNames(styles.selectCaseDropdown, {
          [styles.selectCaseDropdownOpen]: openDropdown,
        })}
      >
        {props.options?.enumOptions &&
          props.options.enumOptions.map((option) => {
            return (
              <div
                key={option.label}
                onClick={handleUpdateSelected.bind(null, option.value)}
                className={classNames(styles.formGroup, {
                  [styles.formGroupSelected]: derivedValue === option.value,
                })}
              >
                <input
                  type="radio"
                  name="reason"
                  value={option.value}
                  checked={option.value === derivedValue}
                  onChange={handleUpdateSelected.bind(null, option.value)}
                  id={option.value}
                />
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
}
