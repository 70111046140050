import { useTranslation } from "react-i18next";

import { Card } from "components/Card/Card";
import { Icon } from "components/Icon";
import { Pill } from "components/Pill";
import { Table } from "components/Table";
import { Tabs } from "components/Tabs";

import { getStageStatus } from "lib/helpers";

import { CaseCancel } from "./CaseCancel";
import { CaseEventLog } from "./CaseEventLog";
import styles from "./CaseInformation.module.scss";
import { CaseInvoiceHistory } from "./CaseInvoiceHistory";
import { CasePause } from "./CasePause";
import { CaseState, CaseView, ChangeCaseState } from "./types";

type CaseInformationProps = {
  caseId: string;
  customerId: string;
  view: CaseView["state"];
  onChangeView: CaseView["setter"];
  caseData?: SingleCaseRes["invoice"];
  caseState: CaseState;
  casePayments: any[];
  onChangeCaseState: ChangeCaseState;
};

export function CaseInformation({
  caseId,
  view,
  onChangeView,
  caseData,
  caseState,
  casePayments,
  onChangeCaseState,
}: CaseInformationProps) {
  const { t } = useTranslation();

  if (!caseData) return;

  return (
    <Card>
      <div className={styles.caseInformationWrapper}>
        <header className={styles.caseInformationHeader}>
          <div>
            <h2>{t("pages.casePage.caseId")}</h2>
            <Pill text={caseId} intent="Dark" />
          </div>
          <div>
            <Pill
              text={getStageStatus(caseData.status).caseStage}
              intent="Dark"
            />
            <Pill
              intent={
                getStageStatus(caseData.status).caseStatus === "Active"
                  ? "Active"
                  : "Purple"
              }
              text={
                <>
                  <Icon name="check" size={12} /> {caseData.status}
                </>
              }
            />
          </div>
        </header>
        <section className={styles.caseInformationContent}>
          {/* If information view is rendered */}
          {view === "information" ? (
            <Tabs
              tabs={[
                {
                  id: "eventLog",
                  title: t("pages.casePage.eventLogTabTitle"),
                  content: (
                    <CaseEventLog
                      reciprocalType={caseState.reciprocalType}
                      onChangeInformation={onChangeCaseState}
                      searchedTerm={caseState.search}
                      invoiceItems={casePayments}
                    />
                  ),
                },
                {
                  id: "invoiceHistory",
                  title: t("pages.casePage.caseInvoiceHistoryTabTitle"),
                  content: (
                    <div
                      className={styles.caseInformationInvoiceHistoryWrapper}
                    >
                      {caseData.Histories.length > 0 ? (
                        <CaseInvoiceHistory
                          caseId={caseId}
                          payments={caseData.Histories}
                          currency={caseData.currency}
                        />
                      ) : (
                        "No case history"
                      )}
                    </div>
                  ),
                },
                {
                  id: "payments",
                  title: t("pages.casePage.paymentsTabTitle"),
                  content: (
                    <div className={styles.caseInformationPaymentsWrapper}>
                      <Table
                        data={casePayments}
                        columns={[]}
                        header={true}
                        borderedTable={true}
                      />
                    </div>
                  ),
                },
              ]}
            />
          ) : (
            <div className={styles.caseActionsWrapper}>
              {caseState.allCases.length > 0 ? (
                <>
                  <h3>
                    {view.pause
                      ? t("pages.casePage.pauseUnpaseCase")
                      : t("pages.casePage.cancelCase")}
                  </h3>
                  <p>
                    <Icon name="informationcircle" size={16} />
                    <span>{t("pages.casePage.caseManagementTooltip")}</span>
                  </p>
                </>
              ) : null}
              {view.cancel ? (
                <CaseCancel
                  onChangeView={onChangeView}
                  caseState={caseState}
                  onChangeCaseState={onChangeCaseState}
                />
              ) : (
                <CasePause
                  status={caseState.status}
                  onChangeView={onChangeView}
                  onChangeCaseState={onChangeCaseState}
                />
              )}
            </div>
          )}
        </section>
      </div>
    </Card>
  );
}
