import { Column } from "@tanstack/react-table";
import { useMemo } from "react";

import { Filters } from "components/Filters";
import { BaseInputTemplate } from "components/Form/BaseInputTemplate";
import { DateWidget } from "components/Form/DateWidget";
import { FieldTemplate } from "components/Form/FieldTemplate";
import { SelectWidget } from "components/Form/SelectWidget";
import { Range } from "components/Range";

interface TableFilterProps<TData> {
  column: Omit<Column<TData, unknown>, "columnDef"> & {
    columnDef: Pick<Column<TData, unknown>, "columnDef">["columnDef"] & {
      extractSpecificData?: string;
    };
  };
}

export function TableFilter<TData>({ column }: TableFilterProps<TData>) {
  const label = column.columnDef.header;
  // @ts-expect-error filterType is a custom prop
  const filterType = column.columnDef.filterType;

  const sortedUniqueValues = useMemo(
    () =>
      typeof column.getFacetedUniqueValues().keys().next().value === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column],
  );

  switch (filterType) {
    case "checkboxes":
      return (
        <Filters
          title={column.id}
          value={column.getFilterValue() as string}
          onChange={(value) => {
            column.setFilterValue(value);
          }}
          filters={sortedUniqueValues.map((f) => ({ value: f }))}
        />
      );
    case "text":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          {/* @ts-expect-error not all props */}
          <BaseInputTemplate
            placeholder="Search..."
            onChange={(value: string) => {
              column.setFilterValue(value?.toLowerCase());
            }}
          />
        </FieldTemplate>
      );
    case "dropdown":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          {/* @ts-expect-error not all props */}
          <SelectWidget
            onChange={(value: string) => {
              column.setFilterValue(value);
            }}
            multiple={true}
            value={(column.getFilterValue() as string[]) ?? []}
            options={{
              enumOptions: sortedUniqueValues.map((f) => ({
                value: f,
                label: f,
              })),
            }}
          />
        </FieldTemplate>
      );
    case "range":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <Range
            min={column.getFacetedMinMaxValues()?.[0]}
            max={column.getFacetedMinMaxValues()?.[1]}
            value={
              (column.getFilterValue() as [number, number])?.[1] ??
              column.getFacetedMinMaxValues()?.[1]
            }
            onChange={({ target }: any) =>
              column.setFilterValue([0, parseInt(target.value, 10)])
            }
          />
        </FieldTemplate>
      );
    case "date":
      return (
        // @ts-expect-error not all props
        <FieldTemplate label={label}>
          <DateWidget
            value={column.getFilterValue() as [Date, Date]}
            onChange={(value) =>
              !value.startDate && !value.endDate
                ? column.setFilterValue(undefined)
                : column.setFilterValue([value.startDate, value.endDate])
            }
            range={true}
          />
        </FieldTemplate>
      );
    default:
      return null;
  }
}
