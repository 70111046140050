import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { WidgetProps } from "@rjsf/utils";

import { Icon } from "components/Icon";

import { classNames } from "lib/classNames";
import { useOutsideClick } from "lib/hooks/useOutsideClick";

import styles from "./SelectCase.module.scss";
import { CaseState, ChangeCaseState } from "./types";

export function SelectMultipleCases(
  props: WidgetProps & {
    onChangeCaseState: ChangeCaseState;
    caseState: CaseState;
  },
) {
  const { value, onChange, onChangeCaseState, caseState } = props;

  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState(
    caseState.cancelMultipleCases.cases.length > 0,
  );

  const handleChangeDropdownVisibility = () => {
    setOpenDropdown(true);
  };

  const handleUpdateSelected = (val: string) => {
    if (
      // @ts-expect-error Enum is a string array
      val === props.schema?.items?.enum[0] &&
      !caseState.cancelMultipleCases.cases.includes(val)
    ) {
      onChangeCaseState("cancelMultipleCases", {
        // @ts-expect-error Enum is a string array
        cases: props.schema?.items?.enum.slice(0),
        date: caseState.cancelMultipleCases.date,
      });

      onChange(
        // @ts-expect-error Enum is a string array
        props.schema?.items?.enum.slice(1),
      );

      return;
    }

    onChangeCaseState("cancelMultipleCases", {
      cases: caseState.cancelMultipleCases.cases.includes(val)
        ? caseState.cancelMultipleCases.cases.filter((el) => el !== val)
        : caseState.cancelMultipleCases.cases.concat(val),
    });
    onChange(
      value.includes(val)
        ? value.filter((el: string) => el !== val)
        : value.concat(val),
    );
  };

  const handleRemoveSelected = (e: Event, val?: string) => {
    e.stopPropagation();

    if (!val) {
      onChangeCaseState("cancelMultipleCases", {
        cases: [],
        date: caseState.cancelMultipleCases.date,
      });
      onChange([]);
    } else {
      onChangeCaseState("cancelMultipleCases", {
        cases: caseState.cancelMultipleCases.cases.filter((el) => el !== val),
        date: caseState.cancelMultipleCases.date,
      });
      onChange(value.filter((el: string) => el !== val));
    }
    setOpenDropdown(false);
  };

  const divRef = useRef<HTMLDivElement>(null);

  useOutsideClick(divRef, () => {
    setOpenDropdown(false);
  });

  const renderButtons = (
    <div className={styles.multipleCaseButtonsWrapper}>
      {caseState.cancelMultipleCases.cases.length > 0
        ? caseState.cancelMultipleCases.cases.map((el) => (
            <div className={styles.multipleCaseButton} key={el}>
              {el}
              <Icon
                name="delete1"
                intent="black"
                size={12}
                onClick={(e) => handleRemoveSelected(e, el)}
              />
            </div>
          ))
        : t("pages.casePage.multipleOptions")}
    </div>
  );

  return (
    <div className={styles.selectCaseReasonWrapper} ref={divRef}>
      <div className={styles.selectCaseReasonPreview}>
        <button
          type="button"
          className={
            openDropdown
              ? styles.selectedButtonPreview
              : styles.unselectedButtonPreview
          }
          onClick={handleChangeDropdownVisibility}
        >
          {renderButtons}
          <div>
            {caseState.cancelMultipleCases.cases.length > 0 && (
              <Icon
                name="delete1"
                intent="black"
                size={12}
                onClick={handleRemoveSelected}
              />
            )}
            <div
              className={classNames(styles.iconContainer, {
                [styles.iconContainerOpen]: openDropdown,
              })}
            >
              <Icon name="chevronRight" intent="black" size={12} />
            </div>
          </div>
        </button>
      </div>

      <div
        className={classNames(styles.selectCaseDropdown, {
          [styles.selectCaseDropdownOpen]: openDropdown,
        })}
      >
        {/* @ts-expect-error Enum is a string array */}
        {props.schema?.items?.enum &&
          // @ts-expect-error Enum is a string array
          (props.schema?.items?.enum as string[])?.map((option: string) => {
            return (
              <div
                key={option}
                onClick={handleUpdateSelected.bind(null, option)}
                className={classNames(styles.formGroup, {
                  [styles.formGroupSelected]:
                    caseState.cancelMultipleCases.cases.includes(option),
                })}
              >
                <input
                  type="checkbox"
                  name="reason"
                  value={option}
                  checked={caseState.cancelMultipleCases.cases.includes(option)}
                  onChange={handleUpdateSelected.bind(null, option)}
                  id={option}
                />
                <label htmlFor={option}>{option}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
}
