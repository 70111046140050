import { useRef } from "react";

export function useDebouncing() {
  const timer = useRef<number | null>(null);

  function debounce(callback: () => void, delay: number) {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = window.setTimeout(() => {
      callback();
    }, delay);
  }

  return debounce;
}
