import { Dropdown } from "components/Dropdowns";

import { dropdownContent, dropdownBtn } from "./DropdownUser.config";

type Props = {
  title?: string;
  user: User;
  accounts: [];
};

export function DropdownUser({ title, user, accounts }: Props) {
  const data = { title, user, accounts };

  return (
    <Dropdown
      icon={{ name: "usercirclesingle", intent: "black" }}
      btnElement={dropdownBtn(data)}
    >
      {dropdownContent(data)}
    </Dropdown>
  );
}
