import { useState } from "react";
import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import { ModalAction } from "components/Modal";

import styles from "./CasePause.module.scss";
import { ChangeCaseState } from "./types";

type CasePauseProps = {
  status: string;
  onChangeView(view: "information" | { cancel: boolean; pause: boolean }): void;
  onChangeCaseState: ChangeCaseState;
};

export function CasePause({
  status,
  onChangeView,
  onChangeCaseState,
}: CasePauseProps) {
  const { t } = useTranslation();

  const [caseStatus, setCaseStatus] = useState<"pause" | "unpause" | "initial">(
    "initial",
  );

  // Handle button click
  const handleButtonClick = (type: "pause" | "unpause" | "initial") => {
    if (type === "pause" && status.toLowerCase() === "active") {
      onChangeCaseState("status", "Paused");
      setCaseStatus("pause");
    } else if (type === "unpause" && status.toLowerCase() === "paused") {
      onChangeCaseState("status", "Active");
      setCaseStatus("unpause");
    } else {
      setCaseStatus(type);
    }
  };

  return (
    <>
      <div className={styles.casePauseActions}>
        <RInioButton
          disabled={status.toLowerCase() !== "active"}
          text={t("pages.casePage.pauseCase")}
          onClick={handleButtonClick.bind(null, "pause")}
          intent={status.toLowerCase() === "active" ? "primary" : "tertiary"}
        />
        <span>{t("pages.casePage.or")}</span>
        <RInioButton
          disabled={status.toLowerCase() === "active"}
          text={t("pages.casePage.unpauseCase")}
          intent={status.toLowerCase() !== "active" ? "secondary" : "tertiary"}
          style={{ color: "var(--content-subtle)" }}
          onClick={handleButtonClick.bind(null, "unpause")}
        />
      </div>
      <div className={styles.backToCase}>
        <RInioButton
          text={t("pages.casePage.backToCase")}
          icon="arrowRight"
          onClick={() => onChangeView("information")}
        />
      </div>

      {caseStatus !== "initial" && (
        <ModalAction
          isOpen={caseStatus === "pause" || caseStatus === "unpause"}
          onClose={() => setCaseStatus("initial")}
          intent="success"
          title={t("pages.casePage.success")}
          direction="column"
          content={() => {
            if (caseStatus === "pause") {
              return (
                <p className={styles.caseModalText}>
                  {t("pages.casePage.casePausedSuccessfully")}
                </p>
              );
            } else {
              return (
                <p className={styles.caseModalText}>
                  {t("pages.casePage.caseUnpausedSuccesfully")}
                </p>
              );
            }
          }}
          ctaBtns={[
            {
              text: t("pages.casePage.close"),
              onClick: () => setCaseStatus("initial"),
            },
          ]}
        />
      )}
    </>
  );
}
