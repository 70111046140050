import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import styles from "./PageCustomersCases.module.scss";

const columnHelper = createColumnHelper<CustomerRes>();

export const customerColumns = [
  columnHelper.accessor("customer.name", {
    header: "Name",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usercirclesingle",
    },
  }),
  columnHelper.accessor("customer.id", {
    header: "Customer ID",
    cell: (info) => (
      <Link
        to={`/customer/${info.row.original.customer.id}`}
        className={styles.customerIdColumn}
      >
        {info.row.original.customer.id}
      </Link>
    ),
    meta: {
      headerIcon: "userprofilefocus",
    },
  }),
  columnHelper.accessor("customer.countryCode", {
    header: "Country",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "earth",
    },
  }),
  columnHelper.accessor("customerTotalRemainingBalance", {
    header: "Total Remaining Balance",
    cell: (info) => `${info.getValue()} EUR`,
    meta: {
      headerIcon: "bagdollar",
    },
  }),
  columnHelper.accessor("numberOfActiveCases", {
    header: "Open Cases",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "streamlineCoreLineFileText",
    },
  }),
];

export const customerCOColumns = [
  customerColumns[0],
  customerColumns[1],
  columnHelper.accessor("customer.clientId", {
    header: "Client",
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "usermultiplegroup",
    },
  }),
  ...customerColumns.slice(2),
];
