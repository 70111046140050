import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";

import { Carousel } from "components/Carousel";
import { DashboardHeader } from "components/DashboardHeader";
import { GraphStackedBar } from "components/GraphStackedBar";
import { MetricCard, MetricCardSkeleton } from "components/MetricCard";
import { Skeleton } from "components/Skeleton";
import { WidgetsContainer } from "components/WidgetsContainer";

import { formatNumber } from "lib/formatNumber";
import { useHead } from "lib/hooks/useHead";
import { useGetDashboardQuery } from "lib/slices/mockJsonAPISlice";

import styles from "./PageDashboard.module.scss";

export function PageDashboard() {
  const { t } = useTranslation();
  const { data } = useGetDashboardQuery();
  const { user } = useAuth();
  useHead(t("pages.dashboard.header"));

  const metrics = data ? Object.entries(data.metrics) : [];
  const renderSkeletonCards = 6;

  return (
    <main className={styles.main}>
      <DashboardHeader title={t("pages.dashboard.header")} />
      <div className={styles.welcomeContainer}>
        <div className={styles.welcomeText}>
          <img src="/assets/waving-hand.png" alt="waving hand" />
          <p>
            <span>
              {t("pages.dashboard.hey", { account: user?.profile.name })}
            </span>
            {t("pages.dashboard.welcomeText")}
          </p>
        </div>
        <div className={styles.payoutContainer}>
          {!data && <Skeleton width={300} height={35} />}
          {data && (
            <>
              <p>{t("pages.dashboard.mtdPayout")}</p>
              <p>
                {t("currencyEUR", { amount: formatNumber(data?.mtdPayout) })}
              </p>
            </>
          )}
        </div>
      </div>
      <section>
        {!data && (
          <Carousel>
            {[...Array(renderSkeletonCards)].map((_, i) => (
              <MetricCardSkeleton key={i} />
            ))}
          </Carousel>
        )}
        {data && (
          <Carousel>
            {metrics.map(([title, data], x) => (
              <MetricCard
                key={x}
                title={title}
                volume={{
                  number: data.volume.amount,
                  MoM: data.volume.mom,
                  YoY: data.volume.yoy,
                }}
                files={{
                  number: data.files.amount,
                  MoM: data.files.mom,
                  YoY: data.files.yoy,
                }}
                theme={title === "total" ? "blue" : "white"}
              />
            ))}
          </Carousel>
        )}
      </section>
      <section>
        <div className={styles.metricsHeader}>
          <h2>{formatNumber(data?.metrics.total.files.amount ?? 0)}</h2>
          <p>{t("pages.dashboard.totalFiles")}</p>
        </div>
        <GraphStackedBar data={data?.metrics} />
      </section>
      <div className={styles.divider} />
      <section>
        <WidgetsContainer
          header={t("pages.dashboard.widgets")}
          graphData={data?.graphs}
          storageKey="dashboardData"
        />
      </section>
    </main>
  );
}
