import { InvoiceActivity } from "components/InvoiceActivity/InvoiceActivity";


import styles from "./CaseInvoiceHistory.module.scss";

type CaseInvoiceHistoryProps = {
  caseId: string;
  payments: Record<string, any>[];
  currency: string;
};

export function CaseInvoiceHistory({ caseId, payments, currency }: CaseInvoiceHistoryProps) {


  return (
    <div className={styles.caseInformationInvoiceHistory}>
      {payments && (
        <>
          <InvoiceActivity
            timeline={payments.map((el) => ({
              ...el,
              amount: String(el.amount),
              currency: currency,
              type: "paymentConfirmed",
            }))}
          />
        </>
      )}
    </div>
  );
}
