import { Icon } from "components/Icon";

import { COLORS } from "lib/colors";

import styles from "./MetricCard.module.scss";

type Props = {
  title: string;
  number: number;
};

export function Percent({ title, number }: Props) {
  const negative = number < 0;

  return (
    <div className={styles.percent}>
      <p>{title}</p>
      <div>
        <span
          style={{ color: negative ? COLORS.invalid : COLORS.valid }}
        >{`${number}%`}</span>
        <Icon
          name="arrowup"
          size={12}
          // There is no 'arrowdown' icon that matches the design hence the rotation
          style={{ transform: negative ? "rotate(180deg)" : undefined }}
          intent={negative ? "invalid" : "valid"}
        />
      </div>
    </div>
  );
}
