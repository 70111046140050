export function camelCaseToNormal(str: string): string {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function removeUnderscore(str: string): string {
  return str.replace(/_/g, " ");
}

export const copyToClipboard = (value: string): void => {
  navigator.clipboard.writeText(value);
};

export function stringToNumber(str: string): number | string {
  const num = Number(str);
  return isNaN(num) ? str : num;
}

export function countryToCountryCode(country: string): string {
  const countryCodes: { [key: string]: string } = {
    Afghanistan: "AF",
    Albania: "AL",
    Algeria: "DZ",
    Andorra: "AD",
    Angola: "AO",
    "Antigua and Barbuda": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia and Herzegovina": "BA",
    Botswana: "BW",
    Brazil: "BR",
    Brunei: "BN",
    Bulgaria: "BG",
    "Burkina Faso": "BF",
    Burundi: "BI",
    "Cabo Verde": "CV",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Central African Republic": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    Colombia: "CO",
    Comoros: "KM",
    "Congo (Congo-Brazzaville)": "CG",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    "Czechia (Czech Republic)": "CZ",
    "Democratic Republic of the Congo": "CD",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Eswatini: "SZ",
    Ethiopia: "ET",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Greece: "GR",
    Grenada: "GD",
    Guatemala: "GT",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    Haiti: "HT",
    "Holy See": "VA",
    Honduras: "HN",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    Ireland: "IE",
    Israel: "IL",
    Italy: "IT",
    Jamaica: "JM",
    Japan: "JP",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    Kuwait: "KW",
    Kosovo: "XK",
    Kyrgyzstan: "KG",
    Laos: "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libya: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Mauritania: "MR",
    Mauritius: "MU",
    Mexico: "MX",
    Micronesia: "FM",
    Moldova: "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Morocco: "MA",
    Mozambique: "MZ",
    "Myanmar (formerly Burma)": "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    "North Korea": "KP",
    "North Macedonia": "MK",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    "Palestine State": "PS",
    Panama: "PA",
    "Papua New Guinea": "PG",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Poland: "PL",
    Portugal: "PT",
    Qatar: "QA",
    Romania: "RO",
    Russia: "RU",
    Rwanda: "RW",
    "Saint Kitts and Nevis": "KN",
    "Saint Lucia": "LC",
    "Saint Vincent and the Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome and Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Korea": "KR",
    "South Sudan": "SS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    Sweden: "SE",
    Switzerland: "CH",
    Syria: "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    Tanzania: "TZ",
    Thailand: "TH",
    "Timor-Leste": "TL",
    Togo: "TG",
    Tonga: "TO",
    "Trinidad and Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States of America": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    Venezuela: "VE",
    Vietnam: "VN",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
  };

  return countryCodes[country] || "Country not found";
}

export function capitaliseWord(word?: string) {
  return word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : null;
}

export function buildAddressString(
  address: {
    city: string;
    street?: string;
    postcode?: string;
  },
  removeNotReplacedWords = false,
  schema = "{city}, {street} | {postcode}",
) {
  let addresToReturn = schema;

  const adressEntries = Object.entries(address);
  if (adressEntries.filter(([_, value]) => Boolean(value)).length > 0) {
    adressEntries.forEach(([k, v]) => {
      if (schema.includes(k)) {
        // Remove the exact placeholder
        if (v) {
          addresToReturn = addresToReturn.replace(`{${k}}`, v);
        }
      }
    });
  }

  // Clone the string, because it is a primitive value
  let cloneStr = addresToReturn;

  // If there are non replace words, set the second argument of buildAddressString to true
  if (removeNotReplacedWords) {
    const regex = /{\w+}/g;
    let arr;

    let i = 0;
    while ((arr = regex.exec(addresToReturn)) !== null) {
      cloneStr = cloneStr.replace(/[|,]?\s?{\w+}\s?[|,]?/g, "");
      i++;
    }
  }

  return cloneStr;
}

export function convertTimestampToDDMMYY(timestamp: string): string {
  const date = new Date(timestamp);

  // Pad single digit days and months with leading zeros
  const padWithZero = (num: number) => (num < 10 ? "0" + num : num.toString());

  const day = padWithZero(date.getUTCDate());
  const month = padWithZero(date.getUTCMonth() + 1); // Months are zero-based
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

interface CaseMapping {
  caseStage: string;
  caseStatus: string;
}

export function getStageStatus(invoiceStatus: InvoiceStatus): CaseMapping {
  const statusMap: Record<InvoiceStatus, CaseMapping> = {
    created: {
      caseStage: "Invoiced",
      caseStatus: "Active",
    },
    sent: {
      caseStage: "Invoiced",
      caseStatus: "Active",
    },
    paid: {
      caseStage: "Invoiced",
      caseStatus: "Cleared",
    },
    reminder: {
      caseStage: "Reminded",
      caseStatus: "Active",
    },
    debtCollection: {
      caseStage: "Debt Collection",
      caseStatus: "Active",
    },
  };

  return statusMap[invoiceStatus];
}
