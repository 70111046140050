import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import { Provider } from "react-redux";

import { PROD } from "config/common";

import "styles/main.scss";

import App from "./App";
import { store } from "./app/store";
import debugMenu from "./debugMenu";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const oidcConfig: AuthProviderProps = {
  authority: process.env.REACT_APP_KEYCLOAK_AUTHORITY as string,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT as string,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI as string,
  response_type: "code",
  scope: "openid profile email",
};

const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

root.render(
  <StrictMode>
    <Provider store={store}>
      <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
        <App />
      </AuthProvider>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// On non-prod enviroments, enable the debug menu commands
!PROD && debugMenu();
