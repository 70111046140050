import { useTranslation } from "react-i18next";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

import styles from "./DropdownUser.module.scss";
import { DropdownAccount } from "./DropdownUserAccount";
import { DropdownUserRow } from "./DropdownUserRow";

export const dropdownBtn = (data: any) => (
  <div className={styles.dropdownBtn}>
    <p className={styles.dropdownBtnTitle}>{data.title}</p>
    <p className={styles.dropdownBtnEmail}>{data.user.email}</p>
  </div>
);

export const dropdownContent = (data: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const auth = useAuth();

  return (
    <div className={styles.dropdownContent}>
      <DropdownUserRow
        title={t("userDropdown.settings")}
        icon="cog"
        onClick={() => navigate("/settings/general")}
      />
      <DropdownUserRow title={"TODO"} icon="usermultiplegroup" />
      {data?.accounts.map((account: any, i: number) => (
        <DropdownAccount key={i} accountId={account.id} title={account.title} />
      ))}
      <DropdownUserRow
        title={t("logout")}
        icon="logout"
        onClick={() => auth.signoutRedirect()}
      />
    </div>
  );
};
