import { useTranslation } from "react-i18next";

import { Tabs } from "components/Tabs";

import { CancelMultipleCases } from "./CancelMultipleCases";
import { CancelSingleCase } from "./CancelSingleCase";
import styles from "./CaseCancel.module.scss";
import { NoAvailableCases } from "./NoAvailableCases";
import { CaseState, ChangeCaseState } from "./types";

type CaseCancelProps = {
  onChangeView(view: "information" | { cancel: boolean; pause: boolean }): void;
  caseState: CaseState;
  onChangeCaseState: ChangeCaseState;
};

export function CaseCancel({
  onChangeView,
  caseState,
  onChangeCaseState,
}: CaseCancelProps) {
  const { t } = useTranslation();

  return (
    <>
      {caseState.allCases.length > 0 ? (
        <div className={styles.cancelCaseTabWrapper}>
          <Tabs
            tabs={[
              {
                title: t("pages.casePage.cancelSingleCaseTitle"),
                content: (
                  <div className={styles.cancelCaseTabContent}>
                    <CancelSingleCase
                      caseState={caseState}
                      onChangeCaseState={onChangeCaseState}
                      onChangeView={onChangeView}
                    />
                  </div>
                ),
              },
              {
                title: t("pages.casePage.cancelMultipleCaseTitle"),
                content: (
                  <div className={styles.cancelCaseTabContent}>
                    <CancelMultipleCases
                      caseState={caseState}
                      onChangeCaseState={onChangeCaseState}
                      onChangeView={onChangeView}
                    />
                  </div>
                ),
              },
            ]}
          />
        </div>
      ) : (
        // It was complaining about not able to find div in JSX.IntrinsicElements
        <NoAvailableCases onChangeView={onChangeView} />
      )}
    </>
  );
}
