import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "components/Button";
import { SearchBox } from "components/Searchbox";

import { useDebouncing } from "lib/hooks/useDebouncing";

import styles from "./CaseEventLog.module.scss";
import { CaseInvoiceLogItem } from "./CaseInvoiceLogItem";
import { ChangeCaseState } from "./types";

type CaseEventLogProps = {
  reciprocalType: string;
  searchedTerm: string;
  onChangeInformation: ChangeCaseState;
  invoiceItems: Record<string, any>[];
};

export function CaseEventLog({
  reciprocalType,
  onChangeInformation,
  invoiceItems,
  searchedTerm,
}: CaseEventLogProps) {
  const { t } = useTranslation();
  const debounce = useDebouncing();

  let selectedInboundStyle = {
    borderRadius: "25px",
  } as CSSProperties;
  let selectedOutboundStyle = {
    borderRadius: "25px",
    borderWidth: "1px",
  };

  if (reciprocalType === "inbound" || reciprocalType === "outbound") {
    if (reciprocalType === "inbound") {
      selectedInboundStyle = {
        ...selectedInboundStyle,
        ...{
          borderColor: "var(--border-emphasis)",
        },
      };
    }

    if (reciprocalType === "outbound") {
      selectedOutboundStyle = {
        ...selectedOutboundStyle,
        ...{
          borderColor: "var(--border-emphasis)",
        },
      };
    }
  }

  // Handle change event on search box
  const handleChangeEvent = (value: string) => {
    debounce(() => {
      onChangeInformation("search", value ? value.toLowerCase() : "" );
    }, 500);
  };

  // Handle click on button
  const handleClickButton = (reciprocalType: string) => {
    onChangeInformation("reciprocalType", reciprocalType);
  };

  const filterInvoiceItems = invoiceItems.filter((el) => {
    if (reciprocalType) {
      if (el.reciprocalType.toLowerCase() === reciprocalType.toLowerCase()) {
        return JSON.stringify(el).toLowerCase().includes(searchedTerm);
      }
    } else {
      return JSON.stringify(el).toLowerCase().includes(searchedTerm);
    }
  });

  return (
    <div className={styles.caseEventLogWrapper}>
      <SearchBox
        placeholder={t("pages.casePage.searchBy")}
        onChange={handleChangeEvent}
        value={searchedTerm}
      />

      <div className={styles.caseEventLogActions}>
        <Button
          intent={reciprocalType === "inbound" ? "primary-light" : "default"}
          text="Inbound"
          icon={
            reciprocalType === "inbound"
              ? {
                  name: "delete1",
                  size: 16,
                  style: { order: 1 },
                  onClick: (e) => {
                    e.stopPropagation();
                    handleClickButton("");
                  },
                }
              : undefined
          }
          style={selectedInboundStyle}
          onClick={() => handleClickButton("inbound")}
        />
        <Button
          intent={reciprocalType === "outbound" ? "primary-light" : "default"}
          icon={
            reciprocalType === "outbound"
              ? {
                  name: "delete1",
                  size: 16,
                  style: { order: 1 },
                  onClick: (e) => {
                    e.stopPropagation();
                    handleClickButton("");
                  },
                }
              : undefined
          }
          text="Outbound"
          style={selectedOutboundStyle}
          onClick={() => handleClickButton("outbound")}
        />
      </div>
      <div className={styles.caseEventLogItems}>
        {filterInvoiceItems.map((item) => {
          return (
            <CaseInvoiceLogItem
              key={item.id}
              title={item.title}
              subject={item.subject}
              from={item.from}
              date={item.date}
              type={item.type}
            />
          );
        })}
      </div>
    </div>
  );
}
