import { useAuth } from "react-oidc-context";
import { Route, Routes } from "react-router-dom";

import { PrivateSARoute } from "components/PrivateSARoute";
import { Sidebar } from "components/Sidebar";
import sidebarConfig from "components/Sidebar/sidebar.config.json";

import {
  PageCollections,
  PageIdleGraphs,
  PageInbound,
  PageOutbound,
} from "pages/Analytics";
import { PageCase } from "pages/Case";
import { PageCustomer } from "pages/Customer";
import { PageCustomersCases } from "pages/Customers-Cases";
import { PageDashboard } from "pages/Dashboard";
import { PageFailures } from "pages/Failures";
import { PageReports } from "pages/Reports";
import {
  PageAPIKeys,
  PageAccount,
  PageMembers,
  PageWebhooks,
} from "pages/Settings";
import { PageSAClients } from "pages/SuperAdmin";

import styles from "./LayoutDashboard.module.scss";

export function LayoutDashboard() {
  const auth = useAuth();

  if (!auth.user) {
    return;
  }

  return (
    <div className={styles.layout}>
      <Sidebar
        config={sidebarConfig}
        dataUser={auth.user}
        logOutHandler={() => auth.signoutRedirect()}
      />
      <Routes>
        <Route path="/" element={<PageDashboard />} />

        <Route
          path="/customers"
          element={<PageCustomersCases defaultView="customer" />}
        />
        <Route
          path="/cases"
          element={<PageCustomersCases defaultView="case" />}
        />

        <Route path="/customer/case/:caseId" element={<PageCase />} />
        <Route path="/customer/:customerId" element={<PageCustomer />} />
        <Route path="/failures" element={<PageFailures />} />
        <Route path="/reports" element={<PageReports />} />
        <Route path="/analytics/collections" element={<PageCollections />} />
        <Route path="/analytics/inbound" element={<PageInbound />} />
        <Route path="/analytics/outbound" element={<PageOutbound />} />
        <Route path="/analytics/idle" element={<PageIdleGraphs />} />
        <Route path="/settings/general" element={<PageAccount />} />
        <Route path="/settings/members" element={<PageMembers />} />
        <Route path="/settings/api-keys" element={<PageAPIKeys />} />
        <Route path="/settings/webhooks" element={<PageWebhooks />} />

        {/* Super Admin Routes */}
        <Route
          path="/sa/clients"
          element={<PrivateSARoute children={<PageSAClients />} />}
        />
      </Routes>
    </div>
  );
}
