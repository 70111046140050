import { useTranslation } from "react-i18next";

import { Card } from "components/Card/Card";
import { Text } from "components/Text";

import styles from "./InvoiceActivity.module.scss";

type Activity = {
  date: string;
  type: string;
  amount?: string;
  currency?: string;
};

interface GroupedTimeline {
  [key: string]: Activity[];
}

type Props = {
  timeline: Activity[];
};

export function InvoiceActivity({ timeline }: Props) {
  const { t } = useTranslation();

  // Group invoice activity by month
  const groupedTimeline = timeline.reduce(
    (acc: GroupedTimeline, entry: Activity) => {
      const dateStr = "14/07/2024";
      // eslint-disable-next-line prefer-const
      let [day, month, year] = entry.date.split("/").map(Number);
      const date = new Date(year, month - 1, day);

      const monthText = date.toLocaleString("default", { month: "long" });
      year = date.getFullYear();
      const monthYear = `${monthText}-${year}`;

      if (!acc[monthYear]) {
        acc[monthYear] = [];
      }
      acc[monthYear].push(entry);

      return acc;
    },
    {},
  );

  // Sort the grouped timeline by month and year
  const sortedGroupedTimeline = Object.keys(groupedTimeline)
    .sort((a, b) => {
      const [aMonth, aYear] = a.split("-");
      const [bMonth, bYear] = b.split("-");
      const dateA = new Date(`${aYear}-${aMonth}-01`);
      const dateB = new Date(`${bYear}-${bMonth}-01`);
      return dateA.getTime() - dateB.getTime();
    })
    .reduce((acc: GroupedTimeline, key) => {
      acc[key] = groupedTimeline[key];
      return acc;
    }, {});

  return (
    <Card>
      <div className={styles.activityContainer}>
        {Object.keys(sortedGroupedTimeline).map((month) => (
          <div key={month} className={styles.monthContainer}>
            <Text as="h4" style="body" color="---content-emphasis">
              {month}
            </Text>

            <div>
              {sortedGroupedTimeline[month].map((activity, index) => (
                <div key={index} className={styles.activity}>
                  <div>
                    <Text as="p" style="body-small">
                      {t(
                        `pages.casePage.features.invoice.history.${activity.type}`,
                      ) || "Unknown"}
                    </Text>
                    <Text as="p" style="body" color="--content-muted">
                      {activity.date}
                    </Text>
                  </div>
                  <Text as="p" style="body">
                    {activity.amount} {activity.currency}
                  </Text>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}
