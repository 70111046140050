import { FormEvent, useRef } from "react";
import { useTranslation } from "react-i18next";

import { WidgetProps } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { RInioButton } from "ui-core";

import { Form } from "components/Form";
import { DateWidget } from "components/Form/DateWidget";
import { Section } from "components/FormAccountSettings/Section";

import styles from "./CancelCases.module.scss";
import { SelectMultipleCases } from "./SelectMultipleCases";
import { CaseState, ChangeCaseState } from "./types";

type CancelMultipleCasesProps = {
  onChangeView(view: "information" | { cancel: boolean; pause: boolean }): void;
  caseState: CaseState;
  onChangeCaseState: ChangeCaseState;
};

export function CancelMultipleCases({
  onChangeView,
  caseState,
  onChangeCaseState,
}: CancelMultipleCasesProps) {
  const { t } = useTranslation();

  const schema = {
    type: "object",
    properties: {
      cancelation: {
        type: "object",
        properties: {
          selectMultipleCases: {
            type: "array",
            title: t("pages.casePage.selectInvoices"),
            items: {
              type: "string",
              enum: caseState.allCases,
            },
          },
          dateOfCancelation: {
            type: "string",
            title: t("pages.casePage.dateOfCancellation"),
            format: "date",
          },
        },
        required: ["selectMultipleCases", "dateOfCancelation"],
      },
    },
    required: ["cancelation"],
  };

  const uiSchema = {
    cancelation: {
      "ui:widget": (props: WidgetProps) => (
        <Section {...props} fullWidth={true} />
      ),
      "ui:options": {
        hideTitle: true,
      },
      selectMultipleCases: {
        "ui:widget": "select",
        "ui:width": "50%",
        "ui:options": {
          checkbox: true,
        },
      },
      dateOfCancelation: {
        "ui:widget": "date",
        "ui:width": "50%",
        "ui:options": {
          fillSpace: true,
          intent: "black",
          buttonsLayout: ["select-today", "close"],
        },
      },
    },
    "ui:submitButtonOptions": {
      parentProps: {
        hidden: true,
      },
    },
  };

  const formRef = useRef<any>(null);

  return (
    <div className={styles.cancelCasesWrapper}>
      <div>
        <Form
          formRef={formRef}
          validator={validator}
          schema={schema}
          uiSchema={uiSchema}
          widgets={{
            SelectWidget: (props: WidgetProps) => (
              <SelectMultipleCases
                key={caseState.cancelMultipleCases.cases.length}
                onChangeCaseState={onChangeCaseState}
                caseState={caseState}
                {...props}
              />
            ),
            DateWidget: (props: WidgetProps) => {
              return (
                // @ts-expect-error - pass the date widget props
                <DateWidget
                  {...props}
                  onChange={(value: string) => {
                    props.onChange(value);
                    onChangeCaseState("cancelMultipleCases", {
                      date: new Date(value),
                    });
                  }}
                />
              );
            },
          }}
          onSubmit={(e: FormEvent) => {
            alert("submitting " + JSON.stringify(e.formData));

            formRef.current.reset();
            const filterCases = caseState.allCases.filter(el => !e.formData.cancelation.selectMultipleCases.includes(el));

            onChangeCaseState("allCases", filterCases.length > 1 ? filterCases : []);
            onChangeCaseState("cancelMultipleCases", {
              cases: [],
              date: "",
            });
          }}
        />
      </div>
      <div className={styles.cancelSingleCaseActions}>
        <RInioButton
          text="Cancel"
          intent="secondary"
          onClick={() => onChangeView("information")}
        />

        <RInioButton
          text="Cancel Case"
          intent={
            caseState.cancelMultipleCases.cases.length === 0 ||
            caseState.cancelMultipleCases.date === ""
              ? "tertiary"
              : "primary"
          }
          disabled={
            caseState.cancelMultipleCases.cases.length === 0 ||
            caseState.cancelMultipleCases.date === ""
          }
          onClick={() => {
            if (formRef.current) {
              formRef.current.submit();
            }
          }}
        />
      </div>
    </div>
  );
}
