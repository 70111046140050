import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { CustomerAvatarCard } from "components/CustomerAvatarCard";
import { DashboardHeader } from "components/DashboardHeader";
import { Dropdown } from "components/Dropdowns";
import { CaseInformation } from "components/PageCase/CaseInformation";
import { CaseManagement } from "components/PageCase/CaseManagement";
import {
  CaseState,
  CaseView,
  ChangeCaseState,
} from "components/PageCase/types";

import { buildAddressString } from "lib/helpers";
import { useGetCaseByIdQuery } from "lib/slices/clientServiceAPISlice";

import styles from "./PageCase.module.scss";

export function PageCase() {
  const { caseId } = useParams();
  const { t } = useTranslation();

  const { data: caseData } = useGetCaseByIdQuery(caseId as string);

  // View state
  const [view, setView] = useState<CaseView["state"]>("information");

  const handleChangeView: CaseView["setter"] = (view) => {
    setView(view);
  };

  const [caseState, setCaseState] = useState<CaseState>({
    search: "",
    reciprocalType: "",
    status: "Active",
    allCases: [
      t("pages.casePage.selectAll"),
      "Case 1",
      "Case 2",
      "Case 3",
      "Case 4",
      caseId!,
    ],
    cancelSingleCase: {
      reason: "",
      date: "",
    },
    cancelMultipleCases: {
      cases: [],
      date: "",
    },
  });

  const handleChangeCaseState = useCallback<ChangeCaseState>((key, data) => {
    setCaseState((prev) => {
      if (Array.isArray(data)) {
        return {
          ...prev,
          [key]: data,
        };
      } else if (typeof prev[key] === "object" && typeof data === "object") {
        return {
          ...prev,
          [key]: {
            ...prev[key],
            ...data,
          },
        };
      } else {
        return {
          ...prev,
          [key]: data,
        };
      }
    });
  }, []);

  if (!caseData) {
    return <>loading</>;
  }

  return (
    <main className={styles.main}>
      <DashboardHeader
        title={t("pages.casePage.caseTitle")}
        subHeader={
          <>
            <span>{t("pages.casePage.customerTitle")}</span>
            <span>&#8594;</span>
            <span>{caseData.invoice.customerName}</span>
            <span>&#8594;</span>
            <span>{caseId}</span>
          </>
        }
        backBtn={true}
      />
      <section className={styles.caseGrid}>
        <div>
          {/* Avatar Card */}
          <CustomerAvatarCard
            userId={caseData.invoice.customerId}
            userName={caseData.invoice.customerName}
            trb={caseData.invoiceTotalRemainingBalance}
          >
            {/* Case Management */}
            <CaseManagement onChangeView={handleChangeView} />

            {/* Dropdown */}
            <Dropdown
              btnElement={
                <span className={styles.customerInformationDropdownTitle}>
                  {t("pages.casePage.customerInformation")}
                </span>
              }
              icon={{
                name: "informationcircle",
              }}
            >
              <div className={styles.customerInformationDropdownMenu}>
                <div>
                  <h4>{t("pages.casePage.email")}</h4>
                  <p>{caseData.customer.name}</p>
                </div>
                <div>
                  <h4>{t("pages.casePage.phoneNumber")}</h4>
                  <p>{caseData.customer.phone}</p>
                </div>
                <div>
                  <h4>{t("pages.casePage.country")}</h4>
                  <p>{caseData.customer.countryCode}</p>
                </div>
                <div>
                  <h4>{t("pages.casePage.address")}</h4>
                  <p>
                    {buildAddressString(
                      {
                        city: caseData.customer.city,
                        street: caseData.customer.address1,
                        postcode: caseData.customer.zipCode,
                      },
                      true,
                    )}
                  </p>
                </div>
              </div>
            </Dropdown>
          </CustomerAvatarCard>
        </div>

        {/* Case Information */}
        <CaseInformation
          caseId={caseId as string}
          customerId={"customerId " as string}
          view={view}
          onChangeView={handleChangeView}
          caseData={caseData.invoice}
          casePayments={caseData.paymentsForInvoice}
          caseState={caseState}
          onChangeCaseState={handleChangeCaseState}
        />
      </section>
    </main>
  );
}
