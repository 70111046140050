import { CSSProperties, useEffect, useState } from "react";

import { COLORS } from "lib/colors";

import styles from "./GraphStackedBar.module.scss";

export const progressColors: Record<string, string> = {
  active: COLORS.blueLight,
  cleared: COLORS.primary,
  installment: COLORS.secondary,
  paused: COLORS.coral,
  disputed: COLORS.blue,
  canceled: COLORS.pink,
};

interface DataItem {
  progress: number;
}

interface GraphStackedBarProps {
  data?: Record<string, DataItem>;
}

interface Stat {
  label: string;
  progress: number;
  color: string;
}

interface CustomStyles extends CSSProperties {
  "--triangle-size": string;
}

export function GraphStackedBar({ data }: GraphStackedBarProps) {
  const [stats, setStats] = useState<Stat[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const stats = Object.entries(data)
      .filter(([title]) => title !== "total")
      .map(([title, stats]) => {
        return {
          label: title,
          progress: stats.progress,
          color: progressColors[title],
        };
      })
      .sort((a, b) => b.progress - a.progress);

    setStats(stats);
  }, [data]);

  return (
    <div className={styles.stackedBar}>
      <div
        style={{ "--triangle-size": "32px" } as CustomStyles}
        className={styles.stackedBarContainer}
      >
        {stats.map((stat, index) => (
          <div
            key={index}
            style={{
              width: `calc(${stat.progress}% - var(--triangle-size))`,
              backgroundColor: stat.color,
            }}
          >
            <p>{`${stat.progress}%`}</p>
            <span className={styles.triangleContainer}>
              <span
                className={styles.triangle}
                style={{ borderLeftColor: stat.color }}
              ></span>
              <span
                className={styles.triangleBackdrop}
                style={{
                  backgroundColor: stats[index + 1]?.color ?? "white",
                }}
              ></span>
            </span>
            <div className={styles.labelContainer}>
              <span className={styles.border}></span>
              <p>{stat.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
