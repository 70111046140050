import { Fragment, ReactNode, useState } from "react";

import { Icon } from "components/Icon";
import { Tooltip } from "components/Tooltip";

import { classNames } from "lib/classNames";

import styles from "./Tabs.module.scss";

type Props = {
  tabs: {
    id?: string;
    title: "";
    content: ReactNode;
    tooltipText?: string;
  }[];
  defaultIndex?: number;
  onChange?: (index: number, tabId?: string) => void;
  fill?: boolean;
};

export function Tabs({ tabs, onChange, defaultIndex = 0, fill }: Props) {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const borderPos = (100 / tabs.length) * activeIndex;

  const handleChange = (index: number, tabId?: string) => {
    setActiveIndex(index);
    onChange?.(index, tabId);
  };

  return (
    <>
      <div
        className={classNames(styles.tabsContainer)}
        style={{ width: fill ? "100%" : undefined }}
      >
        {tabs.map((tab, index) => (
          <button
            id={tab.title}
            key={index}
            className={classNames(styles.button, {
              [styles.buttonActive]: index === activeIndex,
            })}
            onClick={() => handleChange(index, tab.id)}
          >
            {tab.title}
            {index === activeIndex}
            {tab.tooltipText && index === activeIndex && (
              <span>
                <Tooltip
                  intent="Dark"
                  text={tab.tooltipText}
                  style={{
                    width: "180px",
                    minWidth: "180px",
                    transform: "translate(-50%, -90%)",
                  }}
                  showAbove={true}
                >
                  <Icon name="informationcircle" size={14} />
                </Tooltip>
              </span>
            )}
          </button>
        ))}
        <span
          className={styles.border}
          style={{ left: `${borderPos}%`, width: `${100 / tabs.length}%` }}
        />
      </div>
      <Fragment key={activeIndex}>{tabs[activeIndex].content}</Fragment>
    </>
  );
}
