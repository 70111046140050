import { useRef, useState } from "react";

import { Icon } from "components/Icon";

import styles from "./Carousel.module.scss";

type Props = {
  children: JSX.Element | JSX.Element[];
};

export function Carousel({ children }: Props) {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleArrowClick = (delta: 1 | -1) => {
    if (!ref.current) {
      return;
    }

    // @ts-expect-error Property 'offsetWidth' does not exist on type 'Element'
    const carouselItemWidth = ref.current.children[0].offsetWidth;
    setScrollIndex((prevIndex): number => {
      if (!ref.current) {
        return prevIndex;
      }

      const newScrollLeft = (prevIndex + delta) * carouselItemWidth;
      const maxScrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      setEndReached(newScrollLeft >= maxScrollLeft);

      ref.current.scrollLeft = newScrollLeft;
      return prevIndex + delta;
    });
  };

  return (
    <div className={styles.carouselContainer}>
      <button
        style={{ visibility: scrollIndex === 0 ? "hidden" : "visible" }}
        onClick={() => handleArrowClick(-1)}
      >
        <Icon name="chevronLeft" intent="black" />
      </button>
      <div ref={ref}>{children}</div>
      <button
        style={{ visibility: endReached ? "hidden" : "visible" }}
        onClick={() => handleArrowClick(+1)}
      >
        <Icon name="chevronRight" intent="black" />
      </button>
    </div>
  );
}
