import { useTranslation } from "react-i18next";

import { RInioButton } from "ui-core";

import { Icon } from "components/Icon";

import styles from "./NoAvailableCases.module.scss";

export function NoAvailableCases({
  onChangeView,
}: {
  onChangeView(view: "information" | { cancel: boolean; pause: boolean }): void;
}) {
  const { t } = useTranslation();

  return (
    <div className={styles.nothingToCancel}>
      <p>
        <Icon name="informationcircle" size={16} />
        <span>{t("pages.casePage.noAvailableCasesToCancel")}</span>
      </p>
      <div className={styles.backToCase}>
        <RInioButton
          text={t("pages.casePage.backToCase")}
          icon="arrowRight"
          onClick={() => onChangeView("information")}
        />
      </div>
    </div>
  );
}
