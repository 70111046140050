import { ReactNode } from "react";
import { withAuthenticationRequired } from "react-oidc-context";

type Props = {
  children: ReactNode;
};

function PrivateRoute({ children }: Props) {
  return children;
}

export default withAuthenticationRequired(PrivateRoute, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});
