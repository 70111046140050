import { createColumnHelper } from "@tanstack/react-table";
import { Link } from "react-router-dom";

import { t } from "i18next";

import { Pill } from "components/Pill";

import { formatCurrency } from "lib/formatNumber";

const columnHelper = createColumnHelper<SingleCustomerRes["cases"][0]>();

export const customerCaseColumns = [
  columnHelper.accessor("invoice.id", {
    header: t("pages.customersCases.tableColumns.caseId"),
    cell: ({ row }) => (
      <Link
        to={`/customer/case/${row.original.invoice.id}`}
        style={{ textDecoration: "none" }}
      >
        <Pill text={row.original.invoice.id} color="#0000ee" />
      </Link>
    ),
    meta: {
      headerIcon: "streamLineFileCheck",
    },
  }),
  columnHelper.accessor("invoice.clientOCRReference", {
    header: t("pages.customersCases.tableColumns.referenceNumber"),
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "streamLineFileCheck",
    },
  }),
  columnHelper.accessor("invoice.totalAmount", {
    header: t("pages.customersCases.tableColumns.amount"),
    cell: ({ row }) => (
      <>
        {formatCurrency(
          parseFloat(row.original.invoice.totalAmount),
          row.original.invoice.currency,
        )}
      </>
    ),
    meta: {
      headerIcon: "bagdollar",
    },
  }),
  columnHelper.accessor("invoice.dueDate", {
    header: t("pages.customersCases.tableColumns.dueDate"),
    cell: (info) => info.getValue(),
    meta: {
      headerIcon: "blankcalendar",
    },
  }),
  columnHelper.accessor("lastInvoicePayment", {
    header: t("pages.customersCases.tableColumns.lastPayment"),
    cell: ({ row }) => (
      <>
        {Object.entries(row.original.lastInvoicePayment).map(([key, value]) => (
          <p key={key}>{value}</p>
        ))}
      </>
    ),
    meta: {
      headerIcon: "stageClockHand",
    },
  }),
];
